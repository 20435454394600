const skillCategories = {
  language: "language",
  frontend: "frontend",
  backend: "backend",
  database: "database",
  ai: "ai",
  other: "other",
  example: "example",
};

let skills_list = {
  Java: { level: 80, category: skillCategories.language },
  Python: { level: 85, category: skillCategories.language },
  JavaScript: { level: 85, category: skillCategories.language },
  TypeScript: { level: 70, category: skillCategories.language },
  C: { level: 80, category: skillCategories.language },
  "C++": { level: 80, category: skillCategories.language },
  HTML: { level: 95, category: skillCategories.frontend },
  CSS: { level: 95, category: skillCategories.frontend },
  React: { level: 90, category: skillCategories.frontend },
  "React Native": { level: 75, category: skillCategories.frontend },
  Angular: { level: 40, category: skillCategories.frontend },
  Bootstrap: { level: 30, category: skillCategories.frontend },
  "Express.js": { level: 80, category: skillCategories.backend },
  Django: { level: 70, category: skillCategories.backend },
  Flask: { level: 55, category: skillCategories.backend },
  GraphQL: { level: 40, category: skillCategories.other },
  SQL: { level: 80, category: skillCategories.database },
  MySQL: { level: 40, category: skillCategories.database },
  MongoDB: { level: 65, category: skillCategories.database },
  PostgreSQL: { level: 60, category: skillCategories.database },
  SQLite: { level: 50, category: skillCategories.database },
  TensorFlow: { level: 50, category: skillCategories.ai },
  NumPy: { level: 75, category: skillCategories.ai },
  Pandas: { level: 55, category: skillCategories.ai },
  Git: { level: 70, category: skillCategories.other },
  Unix: { level: 80, category: skillCategories.other },
  Jira: { level: 75, category: skillCategories.other },
  AWS: { level: 30, category: skillCategories.other },
  "scikit-learn": { level: 25, category: skillCategories.ai },
  jQuery: { level: 50, category: skillCategories.frontend },
  GCP: { level: 20, category: skillCategories.other },
  SQLite: { level: 30, category: skillCategories.database },
  Sass: { level: 70, category: skillCategories.frontend },
  "MS Access": { level: 75, category: skillCategories.database },
  Godot: { level: 60, category: skillCategories.other },
  "No Proficiency": { level: 0, category: skillCategories.example },
  "Mid Proficiency": { level: 50, category: skillCategories.example },
  "Full Proficiency": { level: 100, category: skillCategories.example },
};

let colors = {
  language: { lightColor: "#ffcccc", darkColor: "#ff6666" },
  frontend: { lightColor: "#ffccef", darkColor: "#ff80d7" },
  backend: { lightColor: "#fff5cc", darkColor: "#ffcf07" },
  database: { lightColor: "#ccffdd", darkColor: "#00ff55" },
  ai: { lightColor: "#ccffff", darkColor: "#00ffff" },
  other: { lightColor: "#efe7dc", darkColor: "#cfb997" },
  example: { lightColor: "#f2f2f2", darkColor: "#a6a6a6" },
};

export { skills_list, colors, skillCategories };
